import React from "react"
import { ExitSectionStyled } from "../../styles/pages/home"
import { Link } from "gatsby"
import logo from "../../images/startPointer.png"

const ExitSection = () => {
  return (
    <ExitSectionStyled>
      <section id="iconLogo">
        <img src={logo} alt="" />
      </section>
      <h1> El éxito está a un click de distancia, ¿vamos?</h1>
      <section id="buttonMain">
        <a
          href="https://form.typeform.com/to/UyF3aITf"
          target="_blank"
          rel="noopener noreferrer"
          id="applicantBtn"
        >
          Aplica hoy
        </a>
        <Link to="/contact" id="contactBtn">
          Contáctanos
        </Link>
      </section>
    </ExitSectionStyled>
  )
}

export default ExitSection
