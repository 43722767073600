import styled from "styled-components"
import palette from "../styled-palette"

export const StyledMainPlans = styled.main`
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100vw;

  #titleTwo {
    font-family: ${palette.fontSofiaRegular};
    color: ${palette.letterColor};
    letter-spacing: 0.5px;
    font-size: 2em;
    text-align: center;
  }

  #title {
    font-family: ${palette.fontCooperMedium};
    letter-spacing: 0.5px;
    font-size: 3em;
    text-align: center;
  }

  #containerSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-evenly;
    margin: 40px auto auto;

    #articleTwo {
      background-color: ${palette.colorPurple};
      width: 40%;
      box-shadow: ${palette.boxShadowUp};
      border-radius: 25px;
      padding: 40px 20px 20px;
      margin: auto;
      height: 75vh;
      display: grid;
      grid-template-rows: 15% calc(20% - 5px) calc(30% - 5px) 15% 10%;
      grid-gap: 10px;
      justify-content: center;
      color: ${palette.colorWhite};
      
      @media (max-height: 1355px) {
        grid-template-rows: 15% calc(20% - 5px) calc(30% - 5px) 15% 10%;
        height: 85vh;
      }

      h1,
      h2,
      h3,
      h4,
      p,
      button,
      .containerList {
        width: 80%;
        margin: auto;
      }

      button {
        background-color: ${palette.colorWhite};
        border: none;
        box-shadow: none;
        padding: 10px;
        font-family: ${palette.fontSofiaProLight};
        letter-spacing: 0.5px;
        color: ${palette.colorBlack};
        border-radius: 25em;
        cursor: pointer;
        font-size: 2em;
      }

      .footer {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 10px;
        justify-content: end;

        h3, h2 {
          margin-top: 0;
          margin-bottom: 0;
        }
        h2 {
          font-family: ${palette.fontSofiaProBold};
          letter-spacing: 0.5px; 
          font-size: 2.5em;
        }
        h3 {
          font-family: ${palette.fontSofiaProLight};
          font-size: 1.5em;
          letter-spacing: 0.5px;
        }
      }

      .containerList {
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;

        li {
          font-family: ${palette.fontSofiaProLight};
          font-size: 1.5em;
          letter-spacing: 0.5px;
        }
      }

      .icon {
        width: 20px;
        color: #FFC728;
      }

      h1 {
        font-family: ${palette.fontSofiaBold};
        font-size: 3em;
        letter-spacing: 0.5px;
      }

      h4 {
        font-family: ${palette.fontSofiaProLight};
        font-size: 1.7em;
        letter-spacing: 0.5px;
        text-align: justify;
        padding-bottom: 10px;
      }
    }

    #articleOne {
      width: 40%;
      box-shadow: ${palette.boxShadowUp};
      border-radius: 25px;
      padding: 40px 20px 20px;
      margin: auto;
      min-height: 75vh;
      height: 100%;
      overflow-x: auto;
      display: grid;
      grid-template-rows: 15% 25% 25% 10% 5% 10%;
      justify-content: center;
      
      @media (max-height: 1355px ) {
        grid-template-rows: 10% 25% 30% 10% 5% 10%;
        min-height: 85vh;
      }

      .icon {
        width: 24px;
        color: ${palette.letterColor};
      }

      p {
        font-family: ${palette.fontSofiaProLight};
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        font-size: 1.7em;
      }

      h1,
      h2,
      h4,
      p,
      button {
        width: 80%;
        margin: auto;
      }

      h1 {
        font-family: ${palette.fontSofiaBold};
        font-size: 3em;
        letter-spacing: 0.5px;
      }

      h4 {
        font-family: ${palette.fontSofiaProLight};
        font-size: 1.7em;
        letter-spacing: 0.5px;
        text-align: justify;
        padding-bottom: 10px;
      }

      .containerList {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px;

        p {
          margin-top: 0;
          margin-bottom: 0;
        }
      }

      h2 {
        .price {
          font-family: ${palette.fontSofiaProBold};
          font-size: 2em;
          letter-spacing: 0.5px;
        }

        .description {
          font-family: ${palette.fontSofiaProBold};
          letter-spacing: 0.5px;
          font-size: 1em;
        }
      }
      .extra {
        font-family: ${palette.fontSofiaProLight};
        letter-spacing: 0.5px;
        font-size: 1.3em;
        margin: auto;
      }

      #unitBtn {
        background-color: ${palette.colorPurple};
        border: none;
        box-shadow: none;
        padding: 10px;
        font-family: ${palette.fontSofiaProLight};
        font-size: 2em;
        letter-spacing: 0.5px;
        color: ${palette.colorWhite};
        border-radius: 25em;
        cursor: pointer;
      }
    }
  }
  @media (max-width: 850px) {
    #containerSection {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 10px;
      #articleTwo, #articleOne {
        width: 100%;
        height: 75vh;
      }
      
      #articleOne {
        grid-template-rows: 15% 35% 20% 10% 10% 10%;
        #unitBtn {
          font-size: 1.4em;
        }
        h1 {
          font-size: 2em;
        }
        h4 {
          font-size: 1.5em;
        }
        h2 {
          .price {
            font-size: 1.6em;
          }
          .description {
            font-size: 1em;
          }
        }
        .extra {
          font-size: 1.3em;
          width: 80%;
        }
      }
      
      #articleTwo {
        height: 80vh;
        grid-template-rows: 5% 25% 45% 10% 10% 5%;
        button {
          font-size: 1.8em;
          padding: 7px;
        }
        .footer {
          width: 100%;
          h2 {
            width: 50%;
            font-size: 1.8em;
            text-align: center;
          }
          h3 {
            width: 45%;
            font-size: 1.2em;
            text-align: center;
          }
        }
        h1 {
          font-size: 2em;
        }
        h4 {
          font-size: 1.5em;
        }
        h2 {
          .price {
            font-size: 1.6em;
          }
          .description {
            font-size: 1em;
          }
        }
        .extra {
          font-size: 1.3em;
          width: 80%;
        }
      }
    }
    
  }
`;

export const BannerStyled = styled.section`
  display: grid;
  grid-template-columns: 70% 30%;
  width: 100%;
  height: 30vh;
  margin-top: 40px;
  background: rgb(89,38,122);
  background: linear-gradient(49deg, rgba(89,38,122,1) 0%, rgba(89,38,122,1) 70%, rgba(2,193,179,0) 100%);
  
  #description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    h1, p {
      width: 60%;
      margin: 0 auto;
    }
    h1 {
      font-family: ${palette.fontSofiaProBold};
      font-size: 3em;
      color: ${palette.colorWhite};
    }
    p {
      margin-top: 10px;
      font-family: ${palette.fontSofiaProLight};
      font-size: 2em;
      color: ${palette.colorWhite};
      opacity: 45%;
    }
  }
  .containerBnt {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1;
    }
  }
  button {
    background-color: ${palette.letterColor};
    border: none;
    box-shadow: none;
    padding: 10px 40px;
    font-family: ${palette.fontSofiaProLight};
    font-size: 2em;
    letter-spacing: 0.5px;
    color: ${palette.colorWhite};
    border-radius: 25em;
    cursor: pointer;
  }

  @media (max-width: 850px) {
    display: flex;
    flex-wrap: wrap;
    background: rgb(89,38,122);
    .containerBnt {
      width: 100%;
      img {
       display: none; 
      }
    }
    #description {
      h1, p {
        width: 90%;
        margin: 0 auto;
        text-align: center;
      }
      h1 {
        font-size: 1.8em;
      }
      p {
        font-size: 1.3em;
      }
    }
    button {
      font-size: 1.4em;
      padding: 5px 20px;
    }
  }
`;
