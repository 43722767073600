import styled from "styled-components"
import palette from "../styled-palette"
import mainPhoto from "../../images/mainPhoto.jpeg"

export const HomeWrapper = styled.div`
  max-width: 1920px;
  font-size: 10px;
  margin: auto;
  @media (max-width: 1632px) {
    font-size: 8.5px;
  }
  @media (max-width: 1440px) {
    font-size: 7.5px;
  }
  @media (max-width: 1366px) {
    font-size: 7.1px;
  }
  @media (max-width: 768px) {
    font-size: 7.5px;
  }
`

export const BannerStyledMain = styled.main`
  background-image: url("${mainPhoto}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 18em 0;
  width: 100%;
  position: relative;
  #containerText {
    padding: 0 4%;
    @media (max-width: 425px) {
      padding: 0 16px;
    }
    position: relative;
    z-index: 25;
    width: 100%;
    max-width: 75em;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & #buttonContainer {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      align-items: center;
      grid-gap: 3.2em;

      & a {
        text-decoration: none;
        color: ${palette.colorWhite} !important;
        font-size: 3em;
        line-height: 1.2666666666666666em;
        font-weight: 300;
      }
    }
    & h1 {
      font-size: 6em;
      font-family: ${palette.fontCooperMedium};
      line-height: 1.0833333333333333em;
      margin: 0;
      margin-bottom: 0.5em;
    }
    & p {
      font-size: 3em;
      line-height: 1.2666666666666666em;
      margin-bottom: 1.5em;
    }
    & p,
    h1 {
      color: ${palette.colorWhite};
    }
  }
`

export const BackgroundSecond = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(2, 193, 179);
  background: linear-gradient(
    90deg,
    rgba(2, 193, 179, 1) 0%,
    rgba(2, 193, 179, 0.92) 20%,
    rgba(2, 193, 179, 0.08) 98%
  );
  top: 0;
`

export const BannerStyledProfileWorks = styled.section`
  width: 100%;
  padding: 8em 4% 10em 4%;
  background-color: ${palette.colorPurple};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 425px) {
    padding: 8em 16px 10em 16px;
  }

  & h1 {
    font-size: 3em;
    font-family: ${palette.fontSofiaProBold};
    line-height: 1.2333333333333334em;
    color: ${palette.letterColor};
    margin-bottom: 3em;

    @media (max-width: 425px) {
      text-align: center;
    }
  }
  & #ctxOne {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14em, 14em));
    grid-gap: 5em;
    align-items: center;
    max-width: 105em;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 9em;

    @media (max-width: 578px) {
      justify-content: center;
    }

    @media (max-width: 425px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  & #ctxTwo {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(14em, 14em));
    grid-gap: 5em;
    align-items: center;
    width: 100%;
    max-width: 145em;
    justify-content: space-between;

    @media (max-width: 425px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  & .containerIcon {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    h3 {
      font-size: 2.7em;
      font-family: ${palette.fontSofiaProBold};
      color: ${palette.colorWhite};
      text-align: center;
      letter-spacing: 0.5px;
      margin-top: 1em;
      max-width: 7.407407407407407em;
    }
    & .imgLogo {
      width: 14em;
      @media (max-width: 425px) {
        width: 7em;
      }
    }
  }
`

export const BannerStyledSecond = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 60% 1fr;
  grid-gap: 3.2em;
  padding: 8em 4%;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 5em;
  }

  @media (max-width: 425px) {
    padding: 4em 16px;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }

  #ContainerVideo {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50em;
    & .imagePink {
      position: absolute !important;
      width: 15%;
      right: 10%;
      bottom: 0;
      z-index: -1;
      transform: translateX(40%) translateY(70%);
    }
    & .imageGreen {
      position: absolute !important;
      width: 15%;
      left: 0;
      top: 0;
      z-index: -1;
      transform: translateX(-40%) translateY(-40%);
    }
    & img {
    }
    & .container,
    .container-figure {
      position: absolute;
      width: 80%;
      height: 90%;
      @media (max-width: 425px) {
        width: 80%;
      }
      border-radius: 20px;
    }

    .container {
      overflow: hidden;
    }

    .react-player {
      border-radius: 20px;
    }
  }
  #ContainerText {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;

    & h1 {
      font-size: 3.3em;
      font-family: ${palette.fontCooperMedium};
      font-weight: 500;
      text-align: center;
      margin-bottom: 1.6326530612244898em;
      max-width: 15.183673469387756em;
    }

    & p {
      font-family: ${palette.fontSofiaRegular};
      font-weight: 200;
      font-size: 2.3em;
      text-align: center;
      line-height: 1.2666666666666666em;
      max-width: 20.733333333333334em;
    }
  }
`

export const BackgroundOne = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 15;
  & img {
    object-position: 1% 33%;
    object-fit: cover;
    height: 60vh;
    width: 100%;
  }
`

export const BackgroundGraphic = styled.main`
  padding: 0 4%;
  margin-top: 17em;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  @media (max-width: 1024px) {
    margin-top: 10em;
  }

  @media (max-width: 425px) {
    padding: 0 16px;
  }

  #description {
    position: absolute;
    top: 4%;
    left: 50%;
    transform: translateX(-50%) translateY(-5%);
    width: 100%;

    @media (max-width: 1024px) {
      position: relative;
    }

    h1 {
      font-family: ${palette.fontCooperMedium};
      font-size: 4.9em;
      line-height: 1.530612244897959em;
      color: ${palette.colorBlack};
      text-align: center;
      margin-bottom: 1em;
    }

    h2 {
      font-family: ${palette.fontSofiaProBold};
      font-weight: 400;
      font-size: 3em;
      line-height: 1.2333333333333334em;
      color: ${palette.letterColor};
      text-align: center;
    }

    h3 {
      max-width: 20.733333333333334em;
      width: 100%;
      margin: auto;
      font-family: ${palette.fontSofiaRegular};
      line-height: 1.2666666666666666em;
      letter-spacing: 0.5px;
      font-size: 3em;
      color: ${palette.colorBlack};
      text-align: center;
    }
  }

  #imageMainContainer {
    #imageMain {
      width: 100%;
      height: 100%;
      margin: auto;
      object-fit: scale-down;

      @media (max-width: 1024px) {
        height: auto;
        margin-top: 5em;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .custom-carousel {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .company-figure {
    background-color: white;
    box-shadow: ${palette.boxShadowUp};
    border-radius: 100px;
    overflow: hidden;
    height: 25em;
    max-width: 60em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 32px;
    margin: 2em;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  .dot-custom {
    display: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #02c1b3;

    @media (max-width: 768px) {
      display: block;
    }
  }
`

export const BackgroundAbout = styled.main`
  position: relative;
  margin-bottom: 14em;
  padding: 0 4%;

  @media (max-width: 425px) {
    padding: 0 16px;
  }

  #description {
    position: absolute;
    top: -30em;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1024px) {
      position: relative;
      top: auto;
      margin-top: 5em;
    }

    h1 {
      font-family: ${palette.fontCooperMedium};
      font-weight: 400;
      font-size: 4.9em;
      letter-spacing: 0.5px;
      line-height: 1.530612244897959em;
      color: ${palette.colorBlack};
      text-align: center;
    }

    h2 {
      font-family: ${palette.fontSofiaProBold};
      font-weight: 400;
      letter-spacing: 0.5px;
      line-height: 1.2333333333333334em;
      font-size: 3em;

      color: ${palette.letterColor};
      text-align: center;
      margin-bottom: 0.5em;
    }
  }

  #containerList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    column-gap: 8em;
    row-gap: 3.2em;
    margin-top: 10em;
    margin-bottom: 14em;

    @media (max-width: 882px) {
      justify-content: space-around;
    }

    @media (max-width: 660px) {
      grid-template-columns: 1fr;
      justify-content: center;
      grid-gap: 10em;
    }

    .boxItem {
      max-width: 36em;
      width: 100%;
      gap: 10px;
      display: grid;
      grid-template-rows: 11em 8em auto;
      height: 35vh;
      .imageItem {
        margin: auto;
        width: 40%;
        height: 100%;
        .imgGeneral {
          height: 100%;
          object-position: center;
          img {
            object-fit: contain !important;
          }
        }
        @media (max-width: 882px) {
          width: 30%;
        }
      }
      h1 {
        letter-spacing: 0.5px;
        text-align: center;
        font-size: 2.5em;
        line-height: 1.2em;
        font-family: ${palette.fontSofiaProBold};
        margin: auto;
        padding-top: 5px;
      }
      p {
        font-size: 2.2em;
        font-weight: 200;
        line-height: 1.2272727272727273em;
        letter-spacing: 0.5px;
        font-family: ${palette.fontSofiaProLight};
        text-align: center;
      }
    }
  }

  #moreDetail {
    font-size: 3.5em;
    line-height: 1.2em;
    text-align: center;
    margin: 0 auto;
    font-family: ${palette.fontSofiaProBold};
    letter-spacing: 0.5px;
    a {
      color: #30383f;
    }
  }
`

export const TeamAccompanimentStyled = styled.main`
  height: auto;
  background-color: ${palette.letterColor};
  position: relative;
  padding: 9em 4%;

  @media (max-width: 425px) {
    padding: 9em 16px;
  }

  #ContainerOne {
    width: 100%;
    background-color: ${palette.colorWhite};
    margin-left: auto;
    margin-right: auto;
    border-radius: 50px;
    padding: 5em 3.2em 20em 3.2em;

    h2 {
      color: ${palette.letterColor};
      font-family: ${palette.fontSofiaProBold};
      font-size: 3em;
      line-height: 1.2333333333333334em;
      letter-spacing: 0.5px;
      text-align: center;
      margin-bottom: 1em;
    }

    h1 {
      font-family: ${palette.fontCooperMedium};
      font-size: 4.9em;
      line-height: 1.530612244897959em;
      font-weight: 300;
      letter-spacing: 0.5px;
      text-align: center;
      margin-bottom: 1em;
    }

    .button-nav {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }

    #carousel {
      display: flex;
      flex-direction: row;

      .itemCarousel {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(36em, 36em));
        justify-items: center;
        max-width: 36em;
        width: 100%;

        @media (max-width: 425px) {
          grid-template-columns: 1fr;
        }

        .name {
          font-family: ${palette.fontSofiaProBold};
          font-size: 2.7em;
          letter-spacing: 0.5px;
          line-height: 1.1851851851851851em;
          margin: 0;
          text-align: center;
        }
        .position {
          font-family: ${palette.fontSofiaRegular};
          font-size: 2.4em;
          letter-spacing: 0.5px;
          line-height: 1.1851851851851851em;
          margin: 0;
          text-align: center;
        }

        img {
          width: 25em;
          height: 25em;
          object-fit: contain;
          object-position: center;
          margin-bottom: 3.2em;
          //border-radius: 50%;
          //background-color: red;
        }

        button,
        a {
          font-family: ${palette.fontSofiaRegular};
          font-size: 2em;
          line-height: 1.2em;
          margin-top: 1.1851851851851851em;
          background-color: ${palette.colorPurple};
          border: none;
          box-shadow: none;
          color: ${palette.colorWhite};
          padding: 10px 40px;
          border-radius: 25rem;
        }
      }

      #carouselMain {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
      }

      .icon {
        /* width: 70px; */
        height: 7em;
        align-self: center;
        color: ${palette.grayLight};
        cursor: pointer;
      }
    }
  }

  #percentage {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(35em, 35em));
    justify-content: space-between;
    padding-top: 10em;
    grid-gap: 5em;

    @media (max-width: 1157px) {
      justify-content: space-evenly;
    }

    @media (max-width: 768px) {
      justify-content: center;
    }

    @media (max-width: 425px) {
      grid-template-columns: 1fr;
    }

    article {
      max-width: 35em;
      min-width: 30em;
      display: grid;
      grid-template-rows: 50% 50%;
      @media (max-width: 768px) {
        max-width: 100%;
      }
      width: 100%;
      text-align: center;
      color: ${palette.colorWhite};
      gap: 10px;
      justify-content: center;
      align-items: center;
      h1 {
        font-family: ${palette.fontCooperMedium};
        font-size: 8.3em;
        margin: 0;
        line-height: 1.5591397849462365em;
      }
      p {
        font-size: 2.2em;
        line-height: 1.52em;
        font-family: ${palette.fontSofiaRegular};
        width: 50%;
        margin: auto;
        text-align: center;
      }
    }
  }
`

export const TestimonialsStyled = styled.main`
  height: auto;
  min-height: 20vh;
  padding: 10em 4%;

  @media (max-width: 425px) {
    padding: 10em 16px;
  }

  #title {
    font-family: ${palette.fontCooperMedium};
    font-size: 4.9em;
    line-height: 1.530612244897959em;
    letter-spacing: 0.5px;
    text-align: center;
    margin-bottom: 1.9591836734693877em;
  }

  #titleTwo {
    color: ${palette.letterColor};
    font-family: ${palette.fontSofiaProBold};
    font-size: 3em;
    line-height: 1.2333333333333334em;
    letter-spacing: 0.5px;
    text-align: center;
  }

  #containerTestimonial {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(43em, 43em));
    grid-gap: 3.2em;
    justify-content: space-between;
    max-width: 1478px;
    width: 100%;
    margin: auto;

    @media (max-width: 1044px) {
      justify-content: space-evenly;
      grid-gap: 5em;
    }

    @media (max-width: 425px) {
      grid-template-columns: 1fr;
    }

    .itemTestimonial {
      display: flex;
      flex-direction: column;

      h1 {
        font-family: ${palette.fontSofiaBold};
        font-size: 2.7em;
        line-height: 1.1851851851851851em;
        letter-spacing: 0.5px;
        text-align: start;
        margin-left: 10px;
      }

      h2 {
        font-family: ${palette.fontSofiaRegular};
        line-height: 1.1851851851851851em;
        font-size: 2.7em;
        letter-spacing: 0.5px;
        text-align: start;
        margin-left: 10px;
        margin-bottom: 0;
        margin-top: 0;
      }

      h3 {
        font-family: ${palette.fontSofiaRegular};
        font-size: 2.7em;
        line-height: 1.1851851851851851em;
        letter-spacing: 0.5px;
        text-align: start;
        margin-left: 10px;
        color: ${palette.letterColor};
        margin-bottom: 0;
        margin-top: 5px;
      }

      .video {
        //width: 100%;
        height: 24em;
        //background-color: ${palette.letterColor};
        object-fit: contain;
        object-position: center;
        border-radius: 25em;
        padding: 10px;
        margin: auto auto 3.2em;
      }
    }
  }
`

export const NewPromiseStyled = styled.main`
  background-color: ${palette.colorBlueLight};
  display: grid;
  grid-template-columns: minmax(400px, 600px) 1fr;
  margin: auto;
  padding: 8em 4%;

  @media (max-width: 425px) {
    padding: 8em 16px;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 5em;
  }

  #ContainerVideo {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    & #imageGeneral {
      width: 100%;
      height: 100%;
      border-radius: 35px;
      background-color: blueviolet;
      img,
      picture {
        border-radius: 35px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    & .imagePick {
      position: absolute !important;
      width: 10em;
      height: 10em;
      //width: 15%;
      right: 0;
      bottom: 0;
      z-index: 1;
      transform: translateX(40%) translateY(40%);
    }
    & .imageYellow {
      position: absolute !important;
      width: 15%;
      left: 0;
      top: 0;
      z-index: 1;
      transform: translateX(-40%) translateY(-40%);
    }
    & img {
    }
    & .container {
      position: relative;
      max-height: 60em;
      height: 100%;
      max-width: 60em;
      border-radius: 20px;

      @media (max-width: 620px) {
        width: 80%;
      }
    }
  }
  #ContainerDescription {
    margin-top: 40px;
    width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    & #header {
      h2 {
        color: ${palette.letterColor};
        font-family: ${palette.fontSofiaProBold};
        letter-spacing: 0.5px;
        font-size: 3em;
        line-height: 1.2333333333333334em;
        text-align: start;
        margin-bottom: 0.5em;
      }
      h1 {
        font-family: ${palette.fontSofiaRegular};
        font-size: 4.9em;
        font-weight: 900;
        line-height: 1.2040816326530612em;
        letter-spacing: 0.5px;
        text-align: start;
        max-width: 20em;
      }
      h3 {
        font-family: ${palette.fontSofiaRegular};
        font-size: 2.4em;
        line-height: 1.2083333333333333em;
        letter-spacing: 0.5px;
        text-align: start;
        color: ${palette.letterColorGrayLight};
        margin: 1.3333333333333333em 0;
        max-width: 27em;
      }
      button {
        font-size: 3em;
        line-height: 1.2666666666666666em;
        background-color: ${palette.letterColor};
        padding: 8px 40px;
        letter-spacing: 0.5px;
        border-radius: 25rem;
        border: none;
        box-shadow: none;
        color: ${palette.colorWhite};
        font-family: ${palette.fontSofiaRegular};
        cursor: pointer;
        margin-bottom: 1.0666666666666667em;
      }
    }
    & #containerList {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      & article {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 2.4em;

        & .containerDescription {
          display: flex;
          flex-direction: column;
          gap: 10px;
          & h4 {
            font-family: ${palette.fontSofiaRegular};
            font-weight: 400;
            letter-spacing: 0.5px;
            font-size: 1.8em;
            margin-left: 10px;
          }
        }
        & span {
          font-family: ${palette.fontSofiaRegular};
          padding: 10px;
          align-self: center;
          font-size: 2.5em;
          background-color: ${palette.letterColor};
          color: ${palette.colorWhite};
          width: 1.5em;
          height: 1.5em;
          line-height: 1.2em;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: flex-start;
        }
        & p {
          font-family: ${palette.fontSofiaRegular};
          font-size: 2.5em;
          margin-left: 10px;
        }
      }
    }
  }
`

export const ExitSectionStyled = styled.main`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15em 4%;

  @media (max-width: 425px) {
    padding: 15em 16px;
  }

  #iconLogo {
    width: 200px;
    height: 200px;
    margin-bottom: 6em;
  }
  h1 {
    font-size: 3.5em;
    line-height: 1.530612244897959em;
    font-family: ${palette.fontCooperMedium};
    letter-spacing: 0.5px;
    margin-bottom: 1.0204081632653061em;
    @media (max-width: 880px) {
      text-align: center;
    }
  }
  #buttonMain {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 531px) {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 3.2em;
    }

    button,
    a {
      font-size: 3em;
      letter-spacing: 0.5px;
      padding: 8px 50px;
      border-radius: 45px;
      font-family: ${palette.fontSofiaRegular};
    }
    #applicantBtn {
      border: none;
      box-shadow: none;
      background-color: ${palette.colorPurple};
      color: ${palette.colorWhite};
      cursor: pointer;
      margin-right: 32px;

      @media (max-width: 531px) {
        margin-right: 0;
      }
    }
    #contactBtn {
      cursor: pointer;
      border: 1px solid ${palette.letterColor};
      box-shadow: none;
      background: none;
      color: ${palette.letterColor};
    }
  }
`

export const FaqStyled = styled.main`
  padding: 0 4% 14em 4%;
  width: 100%;
  height: 100%;
  margin: 30px auto;

  @media (max-width: 425px) {
    padding: 0 16px 14em 16px;
  }

  .title {
    font-family: ${palette.fontCooperMedium};
    letter-spacing: 0.5px;
    font-size: 4.9em;
    line-height: 1.530612244897959em;
    margin-bottom: 1em;
    text-align: center;
  }

  #questionFaq {
    max-width: 140em;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1.6em;
    margin: auto auto 8em;

    #active {
      article {
        border-radius: 10px 10px 0 0;
      }

      p {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 1em 2em;
        background-color: ${palette.colorGreenLight};
        font-family: ${palette.fontSofiaProLight};
        font-size: 2.5em;
      }
    }

    article {
      background-color: ${palette.colorGreenLight};
      border-radius: 10px;
      padding: 0 5em;
      height: 11.6em;
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon {
        width: 30px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        font-weight: 200;
      }

      p {
        display: none;
      }

      &:hover {
        background-color: ${palette.letterColor};
      }
    }

    h1 {
      font-family: ${palette.fontSofiaProBold};
      font-size: 3em;
      line-height: 1.2333333333333334em;
    }
  }

  #footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;

    h1 {
      font-family: ${palette.fontSofiaRegular};
      font-size: 3.5em;
      line-height: 1.2em;
      letter-spacing: 0.5px;
      margin-bottom: 0.45714285714285713em;
    }

    h2 {
      font-family: ${palette.fontSofiaProLight};
      font-size: 2.8em;
      line-height: 1.2142857142857142em;
      letter-spacing: 0.5px;
    }
  }
`

export default null
