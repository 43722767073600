import React from "react"
import { StyledMainPlans } from "../styles/pages/plans"
import ExitSection from "../components/home/exitSection"
import { WatchLater } from "styled-icons/material-outlined"
import { BookOpen } from "styled-icons/fa-solid"
import { CommentDetail } from "styled-icons/boxicons-solid"
import { LightningChargeFill, StarFill } from "styled-icons/bootstrap"
// import imageUnion from "../images/imageUnion.jpeg";
import { Helmet } from 'react-helmet';
import Layout from "../components/layout"


const Plans = () => {
  return (
    <Layout>
      <Helmet>
        <meta httpEquiv="refresh" content="0;URL=/" />
      </Helmet>
      {null && <StyledMainPlans>
        <h2 id="titleTwo"> Modalidad de Entrenamiento </h2>
        <h1 id="title"> El plan que más te convenga </h1>
        <section id="containerSection">
          <article id="articleOne">
            <h1> Curso de empleabilidad </h1>
            <h4>
              {" "}
              Aprenderás cómo funciona cada fase de un proceso de reclutamiento y
              las herramientas base que debes tener para incrementar tus
              posibilidades de éxito en cada una de ellas.{" "}
            </h4>
            <div className="containerList">
              <p>
                {" "}
                <BookOpen className="icon" /> Contenido e-learning{" "}
              </p>
              <p>
                {" "}
                <WatchLater className="icon" /> Acceso al contenido 24/7{" "}
              </p>
              <p>
                {" "}
                <CommentDetail className="icon" /> Sección de comentarios{" "}
              </p>
              <p>
                {" "}
                <LightningChargeFill className="icon" /> Garantía de satisfacción
              </p>
            </div>
            <h2>
              NOTA: No incluye el resto de servicios del Programa de
              Empleabilidad.{" "}
            </h2>
            <h2>
              <span className="price"> $46.99 USD </span>
              <span className="description"> Costo anual </span>
            </h2>
            <span className="extra">
              {" "}
              * Si el contenido no te convence, te regresamos tu dinero{" "}
            </span>
            <button type="button" id="unitBtn">
              {" "}
              Únete hoy{" "}
            </button>
          </article>
          <article id="articleTwo">
            <h1> Programa de Empleabilidad </h1>
            <h4>
              Diseñado para impulsar tu perfil y acompañarte hasta que encuentres
              empleo.
            </h4>
            <ul className="containerList">
              <li>
                <StarFill className="icon" /> Postulaciones automáticas y
                recomendación de vacantes acordes a tu perfil
              </li>
              <li>
                <StarFill className="icon" /> Creación de un CV y de un perfil
                atractivo en portales de empleo
              </li>
              <li>
                <StarFill className="icon" /> Acompañamiento personalizado con un
                Career Coach
              </li>
              <li>
                <StarFill className="icon" /> Acceso a contenido en video
              </li>
              <li>
                <StarFill className="icon" /> Muchos beneficios más
              </li>
            </ul>
            <div className="footer">
              <h2> ¡Sin Costo Inicial! </h2>
              <h3> Solo pagas cuando consigues empleo. </h3>
            </div>
            <button type="button"> Únete hoy </button>
          </article>
        </section>
        {/*<BannerStyled>*/}
        {/*  <article id="description">*/}
        {/*    <h1>*/}
        {/*      Conoce más de LaPieza Academy en nuestros eventos semanales.*/}
        {/*    </h1>*/}
        {/*    <p> Entérate de los próximos eventos. </p>*/}
        {/*  </article>*/}
        {/*  <article className="containerBnt">*/}
        {/*    <button>Conoce los próximos eventos </button>*/}
        {/*    <img src={imageUnion} alt="" />*/}
        {/*  </article>*/}
        {/*</BannerStyled> */}
        <ExitSection />
      </StyledMainPlans>}
    </Layout>

  )
}

export default Plans
